/* eslint-disable prettier/prettier */
import React, { useContext } from 'react';

// material-ui
import { Grid, Typography, Table, TableBody, TableCell, TableRow, TableContainer, TableHead, Chip, Button, Stack } from '@mui/material';

// assets
import { makeStyles } from '@mui/styles';
import capitalizeWords from 'utils/capitalizeWords';

// project imports
import PlayerCell from 'views/components/playerCell.component';
import PlayerRatings from 'views/components/playerRatings.component';
import { WindowContext } from 'contexts/WindowContext';
import getReadableDateTime from 'utils/get_readable_datetime';
import getPointDifferentialPercentage from 'utils/pointDifferentialPercentage';

const PoolsTable = ({ eventPools, setActiveMainTab, setSelectedPool, poolAction }) => {
    const { isMobile } = useContext(WindowContext);
    const handlePoolPress = (pool) => {
        poolAction();
        setSelectedPool(pool?.id);
    };

    const useStyles = makeStyles({
        tableCell: {
            borderRight: '1px solid #e0e0e0'
        },
        chipCustom: {
            '& .MuiChip-label': { fontSize: isMobile ? 12 : 13 } // sub-selector
        }
    });

    const styles = useStyles();

    const getPoolStatus = (pool) => {
        switch (pool?.status) {
            case 'completed':
                return <Chip label="Completed" color="success" variant="filled" className={styles.chipCustom} />;
            case 'waiting':
                return <Chip label="Not Started" color="error" variant="filled" className={styles.chipCustom} />;
            case 'in_progress':
                if (pool?.pool_teams[0]?.won === 0 && pool?.pool_teams[0]?.lost === 0) {
                    return <Chip label="Not Started" color="error" variant="filled" className={styles.chipCustom} />;
                }
                return <Chip label="In Progress" color="warning" variant="filled" className={styles.chipCustom} />;
            default:
                return <Chip label="Playing" color="success" variant="filled" className={styles.chipCustom} />;
        }
    };

    function renderRounds(pool) {
        return pool.rounds?.map((round) => (
            <Grid item ml={3}>
                <Grid justifyContent="center" alignItems="center">
                    <Typography variant="h6" color="primary.text" textAlign="center">
                        {`Round ${round?.round_number}`}
                    </Typography>
                    <Grid container>
                        {round.matches?.map((match) => (
                            <Grid
                                ml={0.5}
                                sx={{
                                    width: 24,
                                    height: 12,
                                    backgroundColor: match.status === 'completed' ? 'green' : null,
                                    border: '0.5px solid green'
                                }}
                            />
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        ));
    }

    function renderWithRounds(categoryName, pool) {
        return (
            <>
                <Grid item md={1} sm={2} xs={3}>
                    <Typography
                        variant="h4"
                        sx={{
                            color: 'primary.text',
                            borderRadius: 1
                        }}
                    >
                        {categoryName}
                    </Typography>
                </Grid>
                <Grid item md={2} sm={2} xs={2} mr={-5} sx={{ minWidth: '98px' }}>
                    {getPoolStatus(pool)}
                </Grid>
                <Grid container md={6} sm={0} xs={0} sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
                    {renderRounds(pool)}
                </Grid>
                <Grid
                    item
                    sx={{
                        flexGrow: 1,
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                        alignSelf: 'flex-end',
                        // textAlign: { xs: 'start', md: 'end' },
                        textAlign: 'end'
                        // marginTop: { xs: 2, md: 0 }
                    }}
                    md={3}
                    sm={5}
                    xs={6}
                >
                    <Button
                        variant="contained"
                        size={isMobile ? 'small' : 'medium'}
                        onClick={() => {
                            handlePoolPress(pool);
                        }}
                    >
                        View Pool
                    </Button>
                </Grid>
                <Grid container sm={12} sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' } }} mt={2}>
                    {renderRounds(pool)}
                </Grid>
            </>
        );
    }

    function renderMatchCompletedTimestamp(pool) {
        if (pool.status !== 'completed') {
            return <></>;
        }
        const displayText = getReadableDateTime(pool.updated_at, 'dayTime');
        return (
            <Typography variant="subtitle2" sx={{ pt: 1, minWidth: '75px' }} noWrap>
                {displayText}
            </Typography>
        );
    }

    function renderWithoutRounds(categoryName, pool) {
        return (
            <>
                <Grid item md={2} sm={2} xs={3}>
                    <Typography
                        variant="h4"
                        sx={{
                            color: 'primary.text',
                            borderRadius: 1
                        }}
                    >
                        {categoryName}
                    </Typography>
                </Grid>
                <Grid item md={2} sm={2} xs={3} mr={-5} sx={{ minWidth: '98px' }}>
                    <Stack direction="row" spacing={1}>
                        {getPoolStatus(pool)}
                        {renderMatchCompletedTimestamp(pool)}
                    </Stack>
                </Grid>
                <Grid
                    item
                    sx={{
                        flexGrow: 1,
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                        alignSelf: 'flex-end',
                        // textAlign: { xs: 'start', md: 'end' },
                        textAlign: 'end'
                        // marginTop: { xs: 2, md: 0 }
                    }}
                    md={8}
                    sm={8}
                    xs={6}
                >
                    <Button
                        variant="contained"
                        size={isMobile ? 'small' : 'medium'}
                        onClick={() => {
                            handlePoolPress(pool);
                        }}
                        sx={{
                            minWidth: '100px'
                        }}
                    >
                        View Pool
                    </Button>
                </Grid>
            </>
        );
    }

    function renderPoolHeader(categoryName, pool) {
        return (
            <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                mt={2}
                sx={{
                    backgroundColor: 'white',
                    borderRadius: 1,
                    paddingX: 2,
                    paddingY: 2,
                    borderBottom: '1px solid lightgrey'
                }}
            >
                {pool.rounds?.length > 0 ? renderWithRounds(categoryName, pool) : renderWithoutRounds(categoryName, pool)}
            </Grid>
        );
    }

    function renderTeamHeader(teams) {
        if (isMobile) {
            return 'Team';
        }
        let singlesTotal = 0;
        let doublesTotal = 0;
        let numSingles = 0;
        let numDoubles = 0;
        for (let i = 0; i < teams.length; i += 1) {
            const team = teams[i]?.user?.user_attributes;
            if (team?.dupr_rating && team?.dupr_rating !== 'NR') {
                doublesTotal += Number(team?.dupr_rating);
                numDoubles += 1;
            }
            if (team?.singles_dupr_rating && team?.singles_dupr_rating !== 'NR') {
                singlesTotal += Number(team?.singles_dupr_rating);
                numSingles += 1;
            }
        }
        const doublesDuprRating = doublesTotal !== 0 ? (doublesTotal / numDoubles).toPrecision(3) : 'NR';
        const singlesDuprRating = singlesTotal !== 0 ? (singlesTotal / numSingles).toPrecision(3) : 'NR';
        return (
            <Grid container>
                <Grid>Team</Grid>
                <Grid pt={0.25}>
                    <PlayerRatings
                        ml={3}
                        userAttributes={{
                            dupr_rating: doublesDuprRating,
                            singles_dupr_rating: singlesDuprRating
                        }}
                    />
                </Grid>
            </Grid>
        );
    }

    function renderTableHeader(teams) {
        return (
            <TableHead>
                <TableRow>
                    <TableCell> Rank</TableCell>
                    <TableCell>{renderTeamHeader(teams)}</TableCell>
                    <TableCell align="center" width={70}>
                        {' '}
                        W
                    </TableCell>
                    <TableCell align="center" width={40}>
                        {' '}
                        L
                    </TableCell>
                    <TableCell align="center" width={40}>
                        {' '}
                        PE
                    </TableCell>
                    <TableCell align="center" width={40}>
                        {' '}
                        PA
                    </TableCell>
                    <TableCell align="center" width={40}>
                        {' '}
                        PD
                    </TableCell>
                    <TableCell align="center" width={40}>
                        {' '}
                        PD%
                    </TableCell>
                </TableRow>
            </TableHead>
        );
    }

    function renderMatchTeamRow(team, teamIndex) {
        // don't bother rendering if player was a sub, then withdrew.
        if (team?.is_sub && team?.absent) {
            return <></>;
        }
        return (
            <TableRow key={team.id}>
                <TableCell width={85} className={styles.tableCell} align="center" sx={{ maxWidth: '45px' }}>
                    <Typography variant="subtitle1">{team.rank ? team.rank : teamIndex + 1}</Typography>
                </TableCell>
                <TableCell
                    sx={{
                        flexDirection: 'column',
                        minWidth: '225px',
                        whiteSpace: 'nowrap'
                    }}
                    className={styles.tableCell}
                >
                    <Grid
                        container
                        sx={{
                            flexDirection: 'column',
                            whiteSpace: 'nowrap',
                            minWidth: '150px'
                        }}
                    >
                        {team.team ? ( // is often just one player and team is not populated
                            team.team?.players.map((player) => (
                                <PlayerCell user={player?.user} isSub={player?.is_sub} isAbsent={player?.absent} />
                            ))
                        ) : (
                            <PlayerCell user={team?.user} isSub={team?.is_sub} isAbsent={team?.absent} />
                        )}
                    </Grid>
                </TableCell>
                <TableCell className={styles.tableCell} align="center">
                    <Grid container justifyContent="center">
                        <Typography alignSelf="flex-end">{team.won}</Typography>
                        {team.head_to_head_wins !== null && team.head_to_head_wins !== undefined && (
                            <Grid
                                item
                                sx={{ backgroundColor: 'secondary.dark', paddingX: 0.75, paddingY: 0.25, borderRadius: 0.5 }}
                                ml={0.25}
                                mb={2}
                            >
                                <Typography color="white" variant="h6" fontSize={10}>
                                    {team.head_to_head_wins}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </TableCell>
                <TableCell className={styles.tableCell} align="center">
                    {team.lost}
                </TableCell>
                <TableCell className={styles.tableCell} align="center">
                    {team.points_earned}
                </TableCell>
                <TableCell className={styles.tableCell} align="center">
                    {team.points_against}
                </TableCell>
                <TableCell className={styles.tableCell} align="center">
                    {team.point_differential}
                </TableCell>
                <TableCell className={styles.tableCell} align="center">
                    {getPointDifferentialPercentage(team.points_earned, team.points_against)}
                </TableCell>
            </TableRow>
        );
    }

    function sortResults(a, b) {
        if (a.won === 0 && a.lost === 0 && b.won === 0 && b.lost === 0) {
            return 0;
        }
        const A_WINS = -1;
        const B_WINS = 1;
        if (a.won === 0 && a.lost === 0) {
            return B_WINS;
        }
        if (b.won === 0 && b.lost === 0) {
            return A_WINS;
        }

        const aWinLoss = a.won - a.lost;
        const bWinLoss = b.won - b.lost;
        if (aWinLoss > bWinLoss) {
            return A_WINS;
        }
        if (bWinLoss > aWinLoss) {
            return B_WINS;
        }

        const aPointDiff = a.points_earned - a.points_against;
        const bPointDiff = b.points_earned - b.points_against;

        if (aPointDiff > bPointDiff) {
            return A_WINS;
        }
        if (bPointDiff > aPointDiff) {
            return B_WINS;
        }

        return 0;
    }

    function renderMatchTable(teams) {
        teams.sort(sortResults);
        return (
            <TableContainer>
                <Table size="small" sx={{ backgroundColor: 'white' }}>
                    {renderTableHeader(teams)}
                    <TableBody>{teams?.map((team, teamIndex) => renderMatchTeamRow(team, teamIndex))}</TableBody>
                </Table>
            </TableContainer>
        );
    }

    return eventPools?.map((pool) => {
        const categoryName = capitalizeWords(pool.name);
        const teams = pool.pool_teams;

        return (
            <Grid key={pool.id} data-test-id="views/components/poolsTable.component">
                {renderPoolHeader(categoryName, pool)}
                {renderMatchTable(teams)}
            </Grid>
        );
    });
};

export default PoolsTable;
