/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
import React from 'react';

// material-ui
import { Grid } from '@mui/material';

// project imports

// assets
import PoolsTable from 'views/components/poolsTable.component';
import TeamsTable from 'views/components/teamsTable.component';
import ProductEmpty from 'views/application/e-commerce/Products/ProductEmpty';
import emptyImage from 'assets/images/empty.png';


const Pools = ({
    eventPools,
    setSelectedPool,
    selectedPool,
    setTab,
    eventId,
    eventName,
    handleGenerateDrawer,
    setActiveMainTab,
    selectedEvent,
    poolAction,
    type,
    showAdmin = true
}) => (
    <Grid item xs={12} data-test-id="views/admin/AdminHub/TournamentManager/Pools">
        {eventPools?.length > 0 ? (
            <PoolsTable
                eventPools={eventPools}
                setTab={setTab}
                setSelectedPool={setSelectedPool}
                setActiveMainTab={setActiveMainTab}
                poolAction={poolAction}
            />
        ) : (
            type === 'tournament' ? (
                <TeamsTable
                    showPoolInfo
                    eventId={eventId}
                    eventName={eventName}
                    showAdmin={showAdmin}
                    handleGenerateDrawer={handleGenerateDrawer}
                    selectedEvent={selectedEvent}
                    type={type}
                />
            ) : (
                <ProductEmpty content="No groups" helperText="Once a pool is created, it will appear here" image={emptyImage}
                    imageWidth={{ xs: 200, sm: 300 }} />
            )
        )}
    </Grid>
);

export default Pools;
